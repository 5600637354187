import React from "react";
import './index.css';
import { Helmet } from "react-helmet"



import Juli from "./Julianne.jpg";



export default function Home() {
  return (

  <div className={"main"}>
    <Helmet>
      <title>Dr. Falconi</title>
      <meta name="title" content="Dr. Falconi"></meta>
      <meta name="description" content="The webpage for Julianne Falconi, MD" />
      
      <meta property="og:type" content="website" />
      <meta property="og:url" content="https://drfalconi.com/" />
      <meta property="og:title" content="Dr. Falconi" />
      <meta property="og:description" content="The webpage for Julianne Falconi, MD" />
      <meta property="og:image" content={"https://drfalconi.com" + Juli} />

      <meta property="twitter:card" content="summary" />
      <meta property="twitter:url" content="https://drfalconi.com/" />
      <meta property="twitter:title" content="Dr. Falconi" />
      <meta property="twitter:description" content="The webpage for Julianne Falconi, MD" />
      <meta property="twitter:image" content={"https://drfalconi.com" + Juli} />

    </Helmet>

    <img src={Juli} alt="Julianne Falconi, MD" />
    <h1>Julianne Falconi, MD</h1>
    <span>Coming Soon</span>
    
  </div>



  )
}
